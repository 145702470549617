import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { remove_from_cart } from '../../../utils'

const CartItem = (props) => {
    const {product} = props
    
    const {t} = useTranslation()
    return (
        <Fragment key = {product.object_id}>
            <div class="domain-cart clearfix">
                <div data-testid = "cart-item" className="dname-added">{product.meta.domain_name}</div>
                {
                    product.meta.order_type === "HOSTING_PROVISIONING" ?
                    <Fragment>
                        <div class="domain-host">{product.meta.plan_name.split('-').join(' ')}<span>&#x20A6;{product.cost.toLocaleString("en-US")}</span></div>
                        <div className="dprice-added">
                            <button onClick={() => remove_from_cart(product.object_id)}>{t("domain_search_page.cart.remove")}</button>
                        </div>	
                    </Fragment> : 
                    <Fragment>
                        <div className="dprice-added">
                            <button onClick={() => remove_from_cart(product.object_id)}>{t("domain_search_page.cart.remove")}</button><span>&#x20A6;{product.cost.toLocaleString("en-US")}</span>
                        </div>	
                    </Fragment>

                }
                
                {product.meta.domain_privacy && product.meta.privacy_cost > 0 &&<div class="domain-privacy">Domain Privacy<span>&#x20A6;{product.meta.privacy_cost.toLocaleString("en-US")}</span></div>}
            </div>
        </Fragment>
    )
}

export default CartItem