import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    type : null,
    name : null,
    user_id : null,
}



export const supportSlice = createSlice({
name: 'cart',
initialState : {...initialState},
reducers : {
    supportLogin : (state, action) => {
        const {type, id, name} = action.payload;
        return {
            ...state,
            type : type,
            name : name,
            user_id : type === 'user' ? id : null,
            organisation_id : type === 'organisation' ? id : null
        }
    },
    supportLogout : () => {
        return initialState;
    }
}
})

export const { supportLogin, supportLogout } = supportSlice.actions

export default supportSlice.reducer