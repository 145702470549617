import { createSlice } from '@reduxjs/toolkit'
import devEnvironment from '../../env-variables/development'
import prodEnvironment from '../../env-variables/production'
import stagingEnv from '../../env-variables/staging'



const initialState = process.env.REACT_APP_ENV === "development" ? devEnvironment : 
process.env.REACT_APP_ENV === "staging" ? stagingEnv : prodEnvironment


export const envSlice = createSlice({
  name: 'env',
  initialState,
  reducers : {
    dev : (state) => {
        return devEnvironment
    },
    prod : (state) => {
        return prodEnvironment
    },
    staging : (state) => {
        return stagingEnv
    }
  }
})

export const { dev , prod} = envSlice.actions

export default envSlice.reducer