import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  isLoggedIn : false,
  loading : false,
  error : {}
}



export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers : {
    loginPending : (state , action) => {
      state.loading = true;
      state.error = {};
    },
    loginFulfilled : (state ,action) => {
      state.loading = false;
      state.isLoggedIn = true
      state.error = {}
    },
    loginRejected : (state , action) => {
      state.error = action.payload;
      state.loading = false
    }
  }
})

export const { loginPending , loginFulfilled , loginRejected } = userSlice.actions

export default userSlice.reducer