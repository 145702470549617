import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  items : [],
  full_cart : null,
}



export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers : {
    setCart : (state , action) => {
      return {
        ...state,
        items : action.payload.cart || [],
        full_cart : action.payload

      } 
    }
  }
})

export const { setCart } = cartSlice.actions

export default cartSlice.reducer