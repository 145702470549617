
const Footer = () => {


    return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-4 col-md-4 col-xs-12">
            <div className="footer-desc">
              <img src="img/white-logo.svg" alt=""/>
              <p>Leanstack is a simple and robust hosting platform, built for designers, developers, small businesses and large teams.</p>
              <ul>
                
                <li><a className='fas fa-facebook-f'></a></li>
                <li><a className='fas fa-twitter'></a></li>
                <li><a className='fas fa-telegram-plane'></a></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-8 col-md-8 col-xs-12">
            
            <div className="footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="/hosting">Hosting</a></li>
                <li><a href="/domains">Domain Search</a></li>
                <li><a href="/domains">Transfer Domain</a></li>
                <li><a href="/hosting">Cloud Hosting</a></li>
              </ul>
            </div>
          
          
            <div className="footer-links">
              <h4>Company</h4>
              <ul>
                <li><a href="mailto:support@leanstack.co">Contact Us</a></li>
                <li><a href="/terms">Terms and Privacy</a></li>
                <li><a href="https://blog.leanstack.co" target="_blank" rel="noreferrer">Blog</a></li>
                <li><a>Privacy Policy</a></li> 
                
              </ul>
            </div>
          
          
            <div className="footer-links">
              <h4>Support</h4>
              <ul>
                <li><a href="mailto:support@leanstack.co">support@leanstack.co</a></li>
                <li><a href="https://support.leanstack.co" target="_blank" rel="noreferrer">Knowledgebase</a></li>
                <li><a href="https://ticket.leanstack.co/portal/newticket" target="_blank" rel="noreferrer">Submit Ticket</a></li>
                {/* <li><a href="https://status.leanstack.co/" target="_blank" rel="noreferrer">System Status</a></li> */}
                
                
              </ul>
            </div>
          

          </div>
        </div>
      </div>
    </div>


    )
}

export default Footer
