import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Axios from "../../Axios";
import ButtonWithSpinner from "../../components/ButtonWithSpinner/ButtonWithSpinner";
import Footer from "../../components/Footer/Footer";
import { setCart } from "../../redux/reducers/cartSlice";
import { clipQuery, isValidDomain, logout, update_cart } from "../../utils";
import CartHeader from "../CartPage/CartHeader";
import Cart from "../DomainSearchPage/Cart/Cart";

const DomainTransfer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [domain, setDomain] = useState("");
    const [error, setError] = useState(null);
    const [isTransferable, setIsTransferable] = useState(false);
    const [message, setMessage] = useState(null);
    const history = useHistory();
    const [loading, setLoading] = useState(null);
    const [epp, setEPP] = useState("");
    const [eppError, setEppError] = useState(null);

    const { env } = useSelector((state) => state);
    const dispatch = useDispatch();

    const search = (domain) => {
        setLoading(true);
        if (!isValidDomain(clipQuery(domain.toLowerCase()))) {
            setError("invalid_format");
            setTimeout(() => setError(false), 3000);
            setLoading(false);
            return;
        }
        Axios()
            .post("/v1/rest/domain_availability/transfer", {
                domain_name: domain.split(".")[0],
                tld: domain.split(".")[1],
            })
            .then((res) => {
                history.push("/transfer", { domain: domain });
                const { tld_available, domain_transfer_available } = res?.data;
                setIsTransferable(domain_transfer_available && tld_available);
                if (domain_transfer_available && tld_available) {
                    setMessage("domain_transfer.available");
                } else {
                    setError("domain_transfer.unavailable");
                    setTimeout(() => setError(null), 3500);
                }
            })
            .catch((err) => {
                setError(err?.response?.data?.message || "try_again");
                setTimeout(() => setError(null), 3500);
            })
            .finally(() => setLoading(false));
    };

    const add = () => {
        if (epp === "") {
            setEppError("epp.empty");
            setTimeout(() => setEppError(null), 3000);
            setLoading(false);
            return;
        }
        const data = {
            span: "YEARLY",
            duration: 1,
            meta: {
                domain_name: "pokerface2.com",
                domain_privacy: true,
                order_type: "DOMAIN_TRANSFER",
                epp_code: epp,
            },
        };
        update_cart(data)
            .then((res) => {
                dispatch(setCart(res.data));
                setMessage("domain_transfer.success");
                setTimeout(() => setMessage(null), 3500);
            })
            .catch((err) => {
                if (err?.resposne?.status === 401) {
                    logout();
                    history.push({
                        pathname: "/checkout/signup",
                        state: { from: "cart" },
                    });
                } else {
                    setError(err?.response?.data?.message || t("try_again"));
                    setTimeout(() => setError(null), 3500);
                }
            });
    };

    useEffect(() => {
        if (location?.state?.domain) {
            const { domain } = location.state;
            setDomain(domain);
            search(domain);
        }
    }, []);

    useEffect(() => {
        if (isTransferable) {
            setIsTransferable(false);
            setMessage(null);
        }
    }, [domain]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isTransferable) add();
        else search(domain);
    };

    return (
        <Fragment>
            <CartHeader text="domain_transfer.header" />
            <div class="add-domain-ctn">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-8">
                            <div class="domain-transfer-ctn shadow">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div class="input-ctn">
                                        <label>{t("domain_transfer.domain_name")}</label>
                                        <input
                                            type="text"
                                            value={domain}
                                            onChange={(e) => setDomain(e.target.value)}
                                            placeholder={t("domain_transfer.domain_name")}
                                        />
                                        {message && <span className="success">{t(message)}</span>}
                                        {error && (
                                            <Fragment>
                                                <br />
                                                <span className="error">{t(error)}</span>
                                            </Fragment>
                                        )}
                                    </div>

                                    {isTransferable && (
                                        <div class="input-ctn">
                                            <label>{t("domain_transfer.epp_code")}</label>
                                            <input
                                                type="text"
                                                placeholder={t("domain_transfer.epp_code")}
                                                value={epp}
                                                onChange={(e) => setEPP(e.target.value)}
                                            />
                                            {eppError && <span className="error">{t(eppError)}</span>}
                                            <div class="transfer-info">{t("domain_transfer.message")}</div>
                                        </div>
                                    )}
                                    {isTransferable ? (
                                        <ButtonWithSpinner
                                            classname="blue-btn block-btn sm-btn"
                                            disabled={epp === ""}
                                            loading={loading}
                                            text="add"
                                            type="submit"
                                        />
                                    ) : (
                                        <ButtonWithSpinner
                                            classname="blue-btn block-btn sm-btn"
                                            loading={loading}
                                            text="search"
                                            type="submit"
                                        />
                                    )}
                                </form>
                            </div>
                        </div>

                        <Cart />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default DomainTransfer;
