
import { useTranslation } from 'react-i18next'

const CartHeader = (props) => {
    const {t} = useTranslation()

  return (
    <div className="domain-search-bg alternative">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h3>{t(props.text)}</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CartHeader