import {useTranslation} from "react-i18next"
import {Home , Globe , Server , Bookmark , Book , LogIn , Grid , ShoppingCart} from "react-feather"
import { Link } from 'react-router-dom'
import * as $ from 'jquery'
import { getCookie, redirectToLogin, toDashboard } from '../../../utils'


const MobileNav = (props) => {
  const {t} = useTranslation()

  const handleClose = () => {
    $('#nav-icon1').removeClass('open')
    $('.mobile-nav').slideToggle()
  }
    return (
        <div className="mobile-nav">
        <div className="nav-links">
          <Link onClick={() => handleClose()} to="/"><span><i><Home size = "17px"/></i></span>{t("nav.home")}</Link>
          <Link onClick={() => handleClose()} to="/domains"><span><i><Globe size = "17px"/></i></span>{t("nav.domains")}</Link>
          <Link onClick={() => handleClose()} to="/hosting"><span><i><Server size = "17px"/></i></span>{t("nav.web_hosting")}</Link>
          {/* <Link onClick={() => handleClose()} to="#"><span><i><Server size = "17px"/></i></span>{t("nav.cloud_hosting")}</Link> */}
          <a onClick={() => handleClose()} to="https://blog.leanstack.co" target="_blank"><span><i><Bookmark size = "17px"/></i></span>{t("nav.blog")}</a>
          <a onClick={() => handleClose()} to="https://support.leanstack.co" target="_blank"><span><i><Book size = "17px"/></i></span>{t("nav.knowledgebase")}</a>
          <Link onClick={() => handleClose()} to="/cart"><span><i><ShoppingCart size="17px"/></i></span>Cart</Link>
          {
          !(getCookie('access-token') || getCookie('auth-id')) &&
          <div>
              <Link onClick = {() => redirectToLogin()} className="blue-link"><span><i><LogIn size = "17px"/></i></span>{t("login.button")}</Link>
          </div>
          }
          <div>
             <Link to="#" onClick = {() => toDashboard()} className="blue-link"><span><i><Grid size = "17px"/></i></span>{t("nav.dashboard")}</Link>
          </div>
        </div>
      </div>

    )
}

export default MobileNav
