import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers : {
        set_plans : (state, action) => {
            return action.payload
        }
    }
})

export const {set_plans} = planSlice.actions;

export default planSlice.reducer;