import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import cartReducer from "./reducers/cartSlice";
import envReducer from "./reducers/envSlice";
import plansReducer from "./reducers/plansSlice";
import userReducer from "./reducers/userSlice";
import supportSlice from "./reducers/supportSlice";
import tldSlice from "./reducers/tldSlice";

const reducers = combineReducers({
  user: userReducer,
  cart: cartReducer,
  plans: plansReducer,
  env: envReducer,
  support: supportSlice,
  tlds: tldSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});
