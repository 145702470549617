import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const tldSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setTLDs: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTLDs } = tldSlice.actions;

export default tldSlice.reducer;
