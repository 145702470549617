import * as Yup from "yup";
import axios from "axios";
import random from "randomstring";
import { setCart } from "./redux/reducers/cartSlice";
import Store from "./redux/store";
import prodEnvironment from "./env-variables/production";
import devEnvironment from "./env-variables/development";
import stagingEnv from "./env-variables/staging";
import { supportLogin } from "./redux/reducers/supportSlice";
import Axios from "./Axios";

const get_cart_id = () => {
    const expiry_date = new Date();
    expiry_date.setDate(expiry_date.getDate() + 1);

    const id = !getCookie("cart_id")
        ? setCookie(
              "cart_id",
              random.generate({
                  length: 16,
                  charset: "QWERTYUIOPASDFGHJKLZXCVBNM1234567890",
              })
          )
        : getCookie("cart_id");

    return id;
};

export const clear_cart_id = () => {
    setCookie("cart_id", null, -20);
};

const addToCart = (domainName, type) => {
    const env = Store.getState().env;
    const url = `${env.API_URL}/v1/rest/cart/add`;
    const data = {
        cart_id: get_cart_id(),
        span: "YEARLY",
        duration: 1,
        meta: {
            domain_name: domainName,
            domain_privacy: false,
            plan_key: "1",
            order_type: type,
        },
    };
    return Axios().post(url, data);
};

const update_cart = (data) => {
    const env = Store.getState().env;
    const url = `${env.API_URL}/v1/rest/cart/add`;
    data.cart_id = get_cart_id();
    return Axios().post(url, data);
};

const getCart = async () => {
    const env = Store.getState().env;
    return Axios().get(`${env.API_URL}/v1/rest/cart/${get_cart_id()}`);
};

const remove_from_cart = (id) => {
    const env = Store.getState().env;
    Axios()
        .post(`${env.API_URL}/v1/rest/cart/remove`, {
            cart_id: get_cart_id(),
            object_id: id,
        })
        .then((res) => {
            Store.dispatch(setCart(res.data));
        });
};

const convert_unit = (unit) => {
    if (unit >= 1000) {
        return `${unit / 1000} TB`;
    } else {
        return `${unit} GB`;
    }
};

export const clipQuery = (name) => {
    const n = name.split(".");
    if ((n[0] === "ww" || n[0] === "www") && n.length > 2) {
        n.shift();
    }
    return n.join(".");
};

const add_hosting = (data) => {
    const env = Store.getState().env;
    const url = `${env.API_URL}/v1/rest/cart/add`;
    return Axios().post(url, data);
};

const get_all_errors = (errors) => {
    const values = Object.keys(errors);
    const ar = [];
    for (let i = 0; i < values.length; i++) {
        const n = values[i];
        ar.push(errors[n].message);
    }
    return ar;
};

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; Domain=.leanstack.co; path=/";
};

const validationSchema = Yup.object({
    customer: Yup.object({
        first_name: Yup.string().required("first_name.required"),
        last_name: Yup.string().required("last_name.required"),
        email: Yup.string().required("email.required"),
        phone: Yup.string().required("phone.required"),
        password: Yup.string()
            .required("password.required")
            .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "password.invalid"),
        confirm_password: Yup.string()
            .required("cpassword.required")
            .oneOf([Yup.ref("password"), null], "cpassword.no_match"),
    }),
    contact: Yup.object({
        address: Yup.string().required("required").max(40, "address.max"),
        country: Yup.string().required("required"),
        state: Yup.string().required("required"),
        // city : Yup.string().required("required"),
        post_code: Yup.string().required("required"),
    }),
});

const contactSchema = Yup.object({
    contact: Yup.object({
        address: Yup.string().required("required").max(40, "address.max"),
        country: Yup.string().required("required"),
        state: Yup.string().required("required"),
        // city : Yup.string().required("required"),
        postcode: Yup.string().required("required"),
    }),
});

const getContactInfo = () => {
    const env = Store.getState().env;
    const data = { "access-token": getCookie("access-token") };
    return Axios().get(env.API_URL + "/v1/contact", {
        withCredentials: true,
        headers: data,
    });
};

const generateOrder = (setLoading, setError, history) => {
    const env = Store.getState().env;
    const data = { "access-token": getCookie("access-token") };
    Axios()
        .get(`${env.API_URL}/v1/rest/cart/${get_cart_id()}/generate_order`, {
            withCredentials: true,
            headers: data,
        })
        .then((res) => {
            localStorage.setItem("order_id", res.data.order_id);
            window.location.href = res.data.payment_url;
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                logout(true);
                history.push({
                    pathname: "/checkout/signup",
                    state: { from: "cart" },
                });
            }
            if (err?.response?.status === 404) {
                history.push("/checkout/contact");
            }
            setLoading(false);
            return;
        });
};

const handleCheckout = async (history, setLoading, setError) => {
    const env = Store.getState().env;
    setLoading(true);
    if (!(getCookie("access-token") || getCookie("auth-id"))) {
        history.push({
            pathname: "/checkout/signup",
            state: { from: "cart" },
        });
        return;
    }
    const data = { "access-token": getCookie("access-token") };
    const isAdmin = await Axios()
        .get(env.API_URL + "/v1/me", { headers: data, withCredentials: true })
        .then((res) => res.data.role === "ADMIN")
        .catch((err) => {
            if (err?.response?.status === 401) {
                logout();
                history.push({
                    pathname: "/checkout/signup",
                    state: { from: "cart" },
                });
            }
            if (err?.response?.status === 404) {
                history.push("/checkout/contact");
            }
            setLoading(false);
            return;
        });
    if (isAdmin) {
        setError("admin_order_error");
        setTimeout(() => setError(null), 2000);
        setLoading(false);
        return;
    }
    getContactInfo()
        .then(() => {
            generateOrder(setLoading, setError, history);
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                logout();
                history.push({
                    pathname: "/checkout/signup",
                    state: { from: "cart" },
                });
            }
            if (err?.response?.status === 404) {
                history.push("/checkout/contact");
            }
            setLoading(false);
            return;
        });
};

const redirectToLogin = (return_page) => {
    const env = Store.getState().env;
    window.location.href = env.ADMIN_URL + "/login";
};

export const logout = () => {
    localStorage.removeItem("auth-id");
    setCookie("access-token", null, -20);
    setCookie("auth-id", null, -20);
};

function getCookie(name) {
    const env = Store.getState().env;
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

const redirect = (target) => {
    window.location.href = target;
};

const toDashboard = () => {
    const env = Store.getState().env;
    window.location.href = `${env.ADMIN_URL}/enterprise/dashboard`;
};

const isValidDomain = (d) => {
    const regex = new RegExp(/(^[^-]([a-z-]){1,256}\.[a-zA-Z0-9()-]{1,63}$)/);
    return regex.test(d.toLowerCase());
};

function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
}

const hasHosting = (name, items) => {
    let isHosted = false;

    for (let i = 0; i < items.length; i++) {
        if (name === items[i]?.meta?.domain_name && items[i]?.meta?.order_type === "HOSTING_PROVISIONING") {
            isHosted = true;
            break;
        }
    }
    return isHosted;
};

const hasDomain = (name, items) => {
    let isWithDomain = false;

    for (let i = 0; i < items.length; i++) {
        if (name === items[i]?.meta?.domain_name && items[i]?.meta?.order_type === "DOMAIN_PROVISIONING") {
            isWithDomain = true;
            break;
        }
    }
    return isWithDomain;
};

const setEnv = () => {
    if (localStorage.getItem("env")) return;
    const env =
        process.env.REACT_APP_ENV === "production"
            ? prodEnvironment
            : process.env.REACT_APP_ENV === "staging"
            ? stagingEnv
            : devEnvironment;
    localStorage.setItem("env", JSON.stringify(env));
};

const setCaretPosition = (elemId, caretPos) => {
    var elem = document.getElementById(elemId);

    if (elem != null) {
        if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.move("character", caretPos);
            range.select();
        } else {
            if (elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            } else elem.focus();
        }
    }
};

const resetSupportData = () => {
    const { support } = Store.getState();
    if (!support.type && getCookie("support")) {
        Store.dispatch(supportLogin(JSON.parse(getCookie("support"))));
    }
};

const getTLDPrice = (tld) => {
    const ar = Store.getState().tlds;
    return parseInt(ar?.find((e) => e.tld === tld)["naira_price"]);
};

export {
    addToCart,
    getCart,
    remove_from_cart,
    convert_unit,
    update_cart,
    get_cart_id,
    add_hosting,
    get_all_errors,
    validationSchema,
    contactSchema,
    handleCheckout,
    redirectToLogin,
    getCookie,
    redirect,
    toDashboard,
    isValidDomain,
    titleCase,
    hasHosting,
    hasDomain,
    setCaretPosition,
    setEnv,
    resetSupportData,
    getTLDPrice,
};
