import { useEffect , useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCart } from '../../../redux/reducers/cartSlice'
import { getCart, handleCheckout} from '../../../utils'
import CartItem from './CartItem'
import { useHistory } from 'react-router'
import ButtonWithSpinner from '../../../components/ButtonWithSpinner/ButtonWithSpinner'


const Cart = (props) => {

	const dispatch = useDispatch()
	const {t} = useTranslation()
	const cart = useSelector(state => state.cart.items)
	const fullcart = useSelector(state => state.cart.full_cart)
	const history = useHistory()
	const [loading , setLoading] = useState(false)
	const {setError} =props

	useEffect(() => {
		if (cart.length === 0) {
			getCart()
			.then(response => {
				dispatch(setCart(response.data))
			})
		}
    }, [])

	const checkout = () => {
		handleCheckout(history, setLoading, setError)
	} 

	const compare = (a, b) => {
		let fa = a.object_id, fb = b.object_id;
		return (fa < fb)? -1 : (fa > fb) ? 1 : 0;
	}	

	const sortedList = () => [...cart].sort((a, b) => compare(a, b));

    return (
		<div className="col-md-4 col-sm-12">
			<div className="cart-ctn">
				<div className="cart shadow">
					<div className="cart-title">
						<i className="feather-shopping-cart"></i>{t('domain_search_page.cart.header')}
					</div>
					{
						cart.length < 1 ?
						<div className="cart-content empty">
							<p>{t('domain_search_page.cart.empty')}</p>
						</div> : 
						<div className="cart-content">
							<div className="domain-cart clearfix">
								{sortedList().map(product => <CartItem product = {product}/>)}
							</div>
							{props.checkout &&<div class="price-total mb-20">{t('domain_search_page.cart.total')} <span>&#x20A6;{fullcart.total_cost.toLocaleString("en-US")}</span></div>}
							{
								props.checkout ? 
								<ButtonWithSpinner loading={loading} onclick={() => checkout()} classname="blue-btn btn-sm btn-block m-t-20" text = 'domain_search_page.cart.checkout'/> :
								<button onClick={() => history.push('/cart')} to = "/cart" className="blue-btn btn-sm btn-block m-t-20" >{t('domain_search_page.cart.continue')}</button>
							}
						</div>
					}
				</div>
			</div>
		</div>

    )
}

export default Cart
