import React from 'react'
import ListLoadingIcon from '../ListLoadingIcon/LIstLoadingIcon'

const SiteLoadingComponent = () => {

    const backgroundStyle = {
        position: 'fixed',    
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '2',
        cursor: 'pointer',
        backgroundColor: '#fff',
    }

    return (
        <div className="d-flex flex-column justify-content-center" style={{...backgroundStyle}}>
            <div class="spinner-ctn">
                <div class="loading-gif">
                    <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <span>Loading</span>
                </div> 
            </div>
        </div>
    )
}

export default SiteLoadingComponent