import { lazy, Suspense, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { getCart, get_cart_id, resetSupportData } from "./utils";
import store from "./redux/store";
import { setCart } from "./redux/reducers/cartSlice";
import { set_plans } from "./redux/reducers/plansSlice";
import Axios from "./Axios";
import SiteLoadingComponent from "./components/SiteLoadingComponent/SiteLoadingComponent";
import Nav from "./components/Nav/Nav";
import { setTLDs } from "./redux/reducers/tldSlice";
import axios from "axios";
import DomainTransfer from "./containers/DomainTransfer/DomainTransfer";

const PageNotFound = lazy(() => import("./containers/404/PageNotFound"));
const DomainsPage = lazy(() => import("./containers/DomainsPage/DomainsPage"));
const DomainSearchPage = lazy(() =>
  import("./containers/DomainSearchPage/DomainSearchPage")
);
const LandingPage = lazy(() => import("./containers/LandingPage/LandingPage"));
const Login = lazy(() => import("./containers/Login/Login"));
const CartPage = lazy(() => import("./containers/CartPage/CartPage"));
const HostingPage = lazy(() => import("./containers/HostingPage/HostingPage"));
const AddDomain = lazy(() => import("./containers/AddDomain/AddDomain"));
const Checkout = lazy(() => import("./containers/Checkout/Checkout"));
const Payments = lazy(() => import("./containers/Payments/Payments"));
function App() {
  const dispatch = useDispatch();
  const env = useSelector((state) => state.env);

  useEffect(() => {
    resetSupportData();
    get_cart_id();
    axios
      .get("https://api.leanstack.co/v1/rest/tlds")
      .then((res) => dispatch(setTLDs(res.data)));
    getCart().then((response) => {
      store.dispatch(setCart(response.data));
    });
  }, []);

  useEffect(() => {
    Axios()
      .get(`${env.API_URL}/v1/rest/plans`)
      .then((res) => dispatch(set_plans(res.data)));
  }, []);

  return (
    <Suspense fallback={<SiteLoadingComponent />}>
      <Fragment>
        <BrowserRouter>
          <Nav />
          <Switch>
            <Route path="/payments" exact component={Payments} />
            <Route path="/login" exact component={Login} />
            <Route path="/domains" exact component={DomainsPage} />
            <Route path="/hosting" exact component={HostingPage} />
            <Route path="/cart" exact component={CartPage} />
            <Route path="/add-domain" exact component={AddDomain} />
            <Route path="/domain-search" exact component={DomainSearchPage} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/transfer" component={DomainTransfer} />
            <Route path="/" exact component={LandingPage} />
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    </Suspense>
  );
}

export default App;
